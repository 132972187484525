const defaults = {
     priorities_available: false,
     priorities_error: false,
     priorities_error_message: '',
     priorities: []
}

const prioritiesReducer = (state = defaults, action) => {

     switch(action.type){
          case "FETCH_PRIORITIES": {
               state = {...state, priorities_available: false};
               break;
          }
          case "DOWNLOAD_ERROR_PRIORITIES": {
               state = {...state,
                    priorities_error: false,
                    priorities_error_message: action.payload
                    };
               break;
          }
          case "POPULATE_PRIORITIES": {
               state = {...state,
                    priorities_available: true,
                    priorities_error: false,
                    priorities: action.payload
                    };
               break;
          }
          default: {
              break;
          }
     }

     return state
}

export default prioritiesReducer;
