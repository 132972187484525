import React, { Component } from 'react';
import { withRouter } from 'react-router';

class SettingsDone extends Component {

     navigateTo(location){
          this.props.router.push(location)
     }

     render() {
          return (
               <div className="settings-pane">
                    <a onClick={ () => this.navigateTo('settings') } className="settings-back">Back</a>
                    <h2>Settings Updated</h2>
                    <div className="settings-form-pane">
                         <p>Your settings have been updated.</p>
                    </div>
               </div>
          );
     }
}

/*
<div className="settings-row" onClick={ () => this.navigateTo('view-clubs') }>Manage Clubs</div>
<div className="settings-row" onClick={ () => this.navigateTo('radio') }>Radio</div>
*/

export default withRouter(SettingsDone);
