const defaults = {
     tracks_available: false,
     tracks_download_error: false,
     tracks_download_errror_message: "",
     tracks: [],
}

const trackReducer = (state = defaults, action) => {

     switch(action.type){
          case "FETCH_TRACKS": {
               state = {...state, tracks_available: false};
               break;
          }
          case "DOWNLOAD_ERROR_TRACKS": {
               state = {...state,
                    tracks_download_error: true,
                    tracks_download_errror_message: action.payload
               };
               break;
          }
          case "REMOVE_TRACK": {

               let trackId = action.payload;
               let tracks = state.tracks;
               let index = tracks.findIndex((x) => x.id === trackId);
               let newTracksList = [].concat(tracks.slice(0, index), tracks.slice(index + 1));

               state = {...state,
                    tracks: newTracksList
                    };
               break;
          }
          case "SKIP_TRACK": {

              let trackId = action.payload;
              let tracks = state.tracks;
              let index = tracks.findIndex((x) => x.id === trackId);
              let newTracksList = [].concat(tracks.slice(0, index), tracks.slice(index + 1));

              newTracksList.push(tracks[index]);

              state = {...state,
                   tracks: newTracksList
                   };
              break;

          }

          case "POPULATE_TRACKS": {
               state = {...state,
                    tracks_available: true,
                    tracks: action.payload
               };
               break;
          }
          case "DOWNLOAD_TRACK_STREAM": {
               //Download Stream
               break;
          }

          case "USER_LOGOUT": {
               state = {...state,
                         tracks_available: false,
                         tracks: []
                         };
               break;
          }
          default: {
              break;
          }
     }

     return state;
}

export default trackReducer;
