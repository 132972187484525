import React, { Component } from 'react';
import { withRouter } from 'react-router';

class Settings extends Component {

     navigateTo(location){
          this.props.router.push(location)
     }

     render() {
          return (
               <div className="settings">
                    <div className="settings-row" onClick={ () => this.navigateTo('contact-info') }>Contact Information</div>
                    <div className="settings-row" onClick={ () => this.navigateTo('radio') }>Radio</div>
                    <div className="settings-row" onClick={ () => this.navigateTo('resort') }>Resort</div>
                    <div className="settings-row" onClick={ () => this.navigateTo('password') }>Password</div>
               </div>
          );
     }
}

/*
<div className="settings-row" onClick={ () => this.navigateTo('view-clubs') }>Manage Clubs</div>
<div className="settings-row" onClick={ () => this.navigateTo('radio') }>Radio</div>
*/

export default withRouter(Settings);
