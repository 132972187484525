import React, { Component } from 'react';
import { connect } from 'react-redux';

import LoadingScreen from './loading-screen';
import Tastemaker from './reaction/Tastemaker';

class Reaction extends Component {

     renderReaction(){

          if(this.props.react_ready){

               const track = this.props.reaction_track;
               const streams = this.props.reaction_track.streams;

               let trackArtworkStyle = {};
               let RactionForm = Tastemaker;
               let trackDetails = this.props.reaction_track.track_details;


               //Artwork
               if(this.props.reaction_track.artwork){
                    trackArtworkStyle = {
                         backgroundImage: 'url('+this.props.reaction_track.artwork+')'
                    };
               }

               return (
                    <div>
                         <div className="react-container">
                              <div className="react-main" style={ trackArtworkStyle }></div>
                              <div className="react-details">
                                   <h2>{ this.props.reaction_track.title }</h2>
                                   <h3>{ this.props.reaction_track.artist } / { this.props.reaction_track.label }</h3>
                                   { trackDetails.split('\n').map(function(item, i) {
                                        return (<p key={i}>{item}</p>)
                                   }) }
                              </div>
                         </div>
                         <div className="react-form">
                              <RactionForm track={ track } tracks={ streams } audioTracks={ streams } trackDetails={ track } dispatch={ this.props.dispatch } />
                         </div>
                         <div className="react-details-mobile">
                              <h2>{ this.props.reaction_track.title }</h2>
                              <h3>{ this.props.reaction_track.artist } / { this.props.reaction_track.label }</h3>
                              { trackDetails.split('\n').map(function(item, i) {
                                   return (<p key={i}>{item}</p>)
                              }) }
                         </div>
                    </div>

               )


          } else {
               return (<LoadingScreen />);
          }
     }


     render() {
          return (
               <div className="react">
               { this.renderReaction() }
               </div>
          );
     }
}

function mapStateToProps(store){
     return {
          react_ready: store.reaction.react_ready,
          react_type: store.reaction.react_type,
          reaction_track: store.reaction.reaction_track
     };
}

export default connect(mapStateToProps)(Reaction);
