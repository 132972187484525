import React, { Component } from 'react';
import { withRouter } from 'react-router';

import { setReactionTrack } from '../actions/reactionActions';

class TrackDisplayList extends Component {

     reactClick(evt) {
          this.props.dispatch(setReactionTrack(this.props));
          this.props.router.push('reaction');
     }

     downloadClick(evt) {
          this.props.router.push('downloads');
     }

     render (){

          let trackArtworkStyle = {};

          if(this.props.artwork){
               trackArtworkStyle = {
                    backgroundImage: 'url('+this.props.artwork+')'
               };
          }

          console.log(this.props);

          return (<div onClick={this.reactClick.bind(this)} className="TrackDisplayList" style={ trackArtworkStyle }>
                       <h2>{ this.props.title }</h2>
                       <p>{ this.props.artist }</p>
                  </div>);
     }
}

export default withRouter(TrackDisplayList);
