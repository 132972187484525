const defaults = {
     history_available: false,
     history_error: false,
     history_error_message: '',
     history_items: []
}

const historyReducer = (state = defaults, action) => {

     switch(action.type){
          case "FETCH_HISTORY": {
               state = {...state, downloads_available: false};
               break;
          }
          case "DOWNLOAD_ERROR_HISTORY": {
               state = {...state,
                    history_error: true,
                    history_error_message: action.payload
                    };
               break;
          }
          case "POPULATE_HISTORY": {
               state = {...state,
                    history_available: true,
                    history_error: false,
                    history_items: action.payload
                    };
               break;
          }
          default: {
              break;
          }
     }

     return state
}

export default historyReducer;
