import React, { Component } from 'react';
import { withRouter } from 'react-router';

class DownloadsNav extends Component {

     navigateTo(location){
          this.props.router.push(location)
     }

     render() {

          let MyDownloadsActiveClass = '',
              AllDownloadsActiveClass = '';

          if(this.props.activeTab==="my_downloads"){
               MyDownloadsActiveClass = 'active';
          } else {
               AllDownloadsActiveClass = 'active';
          }

          return (
               <div>
                    <ul className="downloads-tabs">
                         <li className={ MyDownloadsActiveClass } onClick={ () => this.navigateTo('downloads') }>My Downloads</li>
                         <li className={ AllDownloadsActiveClass } onClick={ () => this.navigateTo('downloads-all') }>All Downloads</li>
                    </ul>
                    <span className="downloads-tabs-clear"></span>
               </div>
          );
     };
}

export default withRouter(DownloadsNav);
