import React, { Component } from 'react';
import ResponsiveEmbed from 'react-responsive-embed';

export default class PriorityItem extends Component {

    render () {
        console.log(this.props.content);

        const { title, description, return_address, top_priority, tracks, you_tube_link} = this.props.content;
        const track_list = tracks.split(',').map((item, idx) => {
            if(item.length>0){
                return (<li key={idx}>{ item }</li>);
            }
            return '';
        });


        return (
            <div className="priority-item">
                <h1>{ title }</h1>
                <p>{ description }</p>
                <p>{ return_address }</p>
                { you_tube_link && (<ResponsiveEmbed src={ you_tube_link } allowfullscreen />)}
                <h3>{ top_priority }</h3>
                <ol>{ track_list }</ol>
            </div>
        );
    }

}
