class UrlHelper {

     constructor(){
          let dataObject = document.getElementById('app');
          this.ajax_request_url = dataObject.dataset.requestUrl;
     }

     get_url_for_ajax(resource){
          return this.ajax_request_url+"/"+resource;
     }

}

export default new UrlHelper();
