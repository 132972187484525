import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Search from './search';
import LoadingScreen from './loading-screen';
import TrackDisplay from './track-display';
import TrackDisplayList from './track-display-list';

import { setReactionTrack } from '../actions/reactionActions';
//import { trackFetch } from "../actions/trackActions";

import UrlHelper from "./../url_helper"

import './start.css';

class Start extends Component {

    constructor(props) {
        super(props);

        let shouldFetchTracks = true,
            hasTracks = false;

        if(this.props.tracks_available){
            if(typeof(this.props.tracks)==="object"){
                hasTracks = true;
                shouldFetchTracks = false;
            }
        }

        this.state = {
            shouldFetchTracks: shouldFetchTracks,
            hasTracks: hasTracks,
            doSearch: false,
            showListLayout: false,
            searchResults: []
        }

    }

    componentDidMount() {
        if(this.state.shouldFetchTracks){
            axios.get(UrlHelper.get_url_for_ajax('get_tracks/'+this.props.user.user_id))
                 .then((response) => {

                     this.props.dispatch({ type: 'POPULATE_TRACKS', payload: response.data })

                     //Get quick link track
                     if(this.props.user.quikLinkTrack.length>0){
                         this.props.tracks.forEach((item, index) => {
                             if(item.id.toLowerCase().indexOf(this.props.user.quikLinkTrack)>-1){
                                 this.props.dispatch({ type: 'QL_SET_TRACK', payload: item })
                             }
                         });
                     }
                 })
                 .catch((err) => {
                      this.props.dispatch({ type: 'DOWNLOAD_ERROR_TRACKS', payload: err })
                 })
        }
    }

    componentDidUpdate() {
        if(typeof(this.props.user.quikLinkReactTrack) === "object") {
            console.log(this.props.user.quikLinkReactTrack);
            this.props.dispatch(setReactionTrack(this.props.user.quikLinkReactTrack));
            this.props.router.push('reaction');
        }
    }

    setLayout(layout) {
        this.setState({
            showListLayout: !this.state.showListLayout
        })
    }

    renderTracks(){

        let trackDisplay = (<LoadingScreen />);
        let tracksLayout = [];
        let tracksToDiplayArr = this.props.tracks;

        if(this.state.doSearch){
            tracksToDiplayArr = this.state.searchResults;
        }

        if(Array.isArray(tracksToDiplayArr)){

            if(this.state.showListLayout){

                //List Layout
                tracksToDiplayArr.map((track, i) => {
                    tracksLayout.push(<TrackDisplayList key={i} {...track} history={this.props.history} dispatch={ this.props.dispatch } />);
                    return true;
                });

            } else {

                //Grid Layout
                tracksToDiplayArr.map((track, i) => {
                    if(i===0){
                        tracksLayout.push(<TrackDisplay key={i} {...track}  history={this.props.history} dispatch={ this.props.dispatch } type='main' />);
                    } else {
                        tracksLayout.push(<TrackDisplay key={i} {...track}  history={this.props.history} dispatch={ this.props.dispatch } />);
                    }
                    return true;
                });
            }

            trackDisplay = (
                <div className="track-container">
                    <div className="subtrack-section">{ tracksLayout }</div>
                </div>
            );


        }

        return (<div>
            <div>{trackDisplay}</div>
            </div>);

    }

    performSearch(searchKeyword) {
        let searchTracksArr = [];

        if(searchKeyword.length>1){

            searchKeyword = searchKeyword.toLowerCase();

            searchTracksArr = this.props.tracks.filter((item, index) => {
                if(item.title.toLowerCase().indexOf(searchKeyword)>-1){
                    return item;
                }
                if(item.artist.toLowerCase().indexOf(searchKeyword)>-1){
                    return item;
                }
                if(item.label.toLowerCase().indexOf(searchKeyword)>-1){
                    return item;
                }
                return "";
            });
            this.setState({
                doSearch: true,
                searchResults: searchTracksArr
            })
            return;
        }

        this.setState({
            doSearch: false,
        })
    }


    render() {

        let listStyle = {};

        if(this.state.doSearch){
            if(this.state.showListLayout){
                listStyle = {
                    "marginTop" : "112px"
                }
            }
        }

        return (<div>       
                    <Search actionSearch={ this.performSearch.bind(this) } />
                    <div className="start-view-button"><button onClick={ this.setLayout.bind(this) } className={(this.state.showListLayout ? "button-top-list" : "button-top-grid")} /></div>
                    <div style={ listStyle }>{ this.renderTracks() }</div>
                </div>);
    }
}

function mapStateToProps(store){
    return {
        tracks_available: store.tracks.tracks_available,
        tracks: store.tracks.tracks,
        user: store.user,
        download_error: store.tracks.tracks_download_error,
    };
}

export default withRouter(connect(mapStateToProps)(Start));
