import axios from "axios"
import UrlHelper from "./../url_helper"

export function getClubs(user_id){

     return function(dispatch){

          dispatch( { type: 'SETTINGS_GET_CLUBS' });

          axios.get(UrlHelper.get_url_for_ajax('get_clubs/'+user_id))
               .then((response) => {
                    dispatch( { type: 'SETTINGS_GET_CLUBS_DONE', payload: response.data })
               })
               .catch((err) => {
                    dispatch( { type: 'SETTINGS_GET_CLUBS_ERROR', payload: err })
               })

     };
}

export function addClub(clubObj){
     return {
          type: 'SETTINGS_ADD_CLUB',
          payload: false,
     }
}

export function saveClub(clubObj){
     return function(dispatch){
          axios.post(UrlHelper.get_url_for_ajax('save-club'), clubObj)
               .then((response) => {
                    dispatch( { type: 'SETTINGS_SAVE_CLUBS', payload: response.data })
               })
               .catch((err) => {
                    dispatch( { type: 'SETTINGS_SAVE_CLUBS_ERROR', payload: err })
               })
     };
}

export function editClub(clubObj){
     return {
          type: 'SETTINGS_EDIT_CLUB',
          payload: false,
     }
}

export function updateClub(clubObj){
     return function(dispatch){
          axios.post(UrlHelper.get_url_for_ajax('update-club'), clubObj)
               .then((response) => {
                    dispatch( { type: 'SETTINGS_UPDATE_CLUBS', payload: response.data })
               })
               .catch((err) => {
                    dispatch( { type: 'SETTINGS_UPDATE_CLUBS_ERROR', payload: err })
               })
     };
}

export function updateRadio(radioObj){
     return function(dispatch){
          axios.post(UrlHelper.get_url_for_ajax('save-radio-details'), radioObj)
               .then((response) => {
                    dispatch( { type: 'SETTINGS_UPDATE_RADIO', payload: response.data })
               })
               .catch((err) => {
                    dispatch( { type: 'SETTINGS_UPDATE_RADIO_ERROR', payload: err })
               })
     };
}

export function updateResort(resortObj){
     return function(dispatch){
          axios.post(UrlHelper.get_url_for_ajax('save-resort'), resortObj)
               .then((response) => {
                    dispatch( { type: 'SETTINGS_UPDATE_RESORT', payload: resortObj.resort })
               })
               .catch((err) => {
                    dispatch( { type: 'SETTINGS_UPDATE_RESORT_ERROR', payload: err })
               })
     };
}

export function updateContactInfo(contactObj) {
     return function(dispatch){
          axios.post(UrlHelper.get_url_for_ajax('save-contact-details'), contactObj)
               .then((response) => {
                    dispatch( { type: 'SETTINGS_UPDATE_CONTACT_INFO', payload: response.data })
               })
               .catch((err) => {
                    dispatch( { type: 'SETTINGS_UPDATE_CONTACT_INFO_ERROR', payload: err })
               })
     };
}

export function changePassword(passwordObj){
     return function(dispatch){
          axios.post(UrlHelper.get_url_for_ajax('save-password'), passwordObj)
               .then((response) => {
                    dispatch( { type: 'SETTINGS_UPDATE_PASSWORD', payload: response.data })
               })
               .catch((err) => {
                    dispatch( { type: 'SETTINGS_UPDATE_PASSWORD_ERROR', payload: err })
               })
     };
}
