const defaults = {
     downloads_available: false,
     downloads_error: false,
     downloads_error_message: '',
     downloads: []
}

const downloadReducer = (state = defaults, action) => {

     switch(action.type){
          case "FETCH_DOWNLOADS": {
               state = {...state, downloads_available: false};
               break;
          }
          case "DOWNLOAD_ERROR_DOWNLOADS": {
               state = {...state,
                    downloads_error: false,
                    downloads_error_message: action.payload
                    };
               break;
          }
          case "POPULATE_DOWNLOADS": {
               state = {...state,
                    downloads_available: true,
                    downloads_error: false,
                    downloads: action.payload
                    };
               break;
          }
          default: {
              break;
          }

     }

     return state
}

export default downloadReducer;
