const defaults = {
     downloads_available: false,
     downloads_error: false,
     downloads_error_message: '',
     downloads: []
}

const myDownloadReducer = (state = defaults, action) => {

     switch(action.type){
          case "FETCH_MY_DOWNLOADS": {
               state = {...state, downloads_available: false};
               break;
          }
          case "DOWNLOAD_ERROR_MY_DOWNLOADS": {
               state = {...state,
                    downloads_error: false,
                    downloads_error_message: action.payload
                    };
               break;
          }
          case "POPULATE_MY_DOWNLOADS": {
               state = {...state,
                    downloads_available: true,
                    downloads_error: false,
                    downloads: action.payload
                    };
               break;
          }
          case "CLEAR_MY_DOWNLOADS_DONE": {
              state = {...state,
                  downloads_available: true,
                  downloads_error: false,
                  downloads: []
              };
              break;
          }
          default: {
              break;
          }
     }

     return state
}

export default myDownloadReducer;
