import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { updateContactInfo } from './../actions/settingsActions';
import axios from 'axios';
import UrlHelper from './../url_helper';

class Settings2 extends Component {

    constructor(props){
        super(props);

        this.state = {
            isReady: false,
            hasError: false,
            latest_phone: props.user.latest_phone,
            latest_email: props.user.latest_email,
            user_id: props.user.user_id,
            hasConnectionError: false,
            hasNoNewPasswordError: false,
            hasNoNewPasswordRepeatError: false,
            hasPasswordMatchError: false,
            hasCheckError: false,
            hasMatchError: false,
            newPassword: '',
            newPasswordRepeat: ''
        }

   }
   
   setMobile(event){
        this.setState({
            latest_phone: event.target.value
        });
   }

    setEmail(event){
        this.setState({
            latest_email: event.target.value
        });
    }
    setNewPassword(event){
        this.setState({
             newPassword: event.target.value
        })
   }
   setNewPasswordRepeat(event){
     this.setState({
          newPasswordRepeat: event.target.value
     })
   }

    updateContactButton() {
          var self = this;

          this.setState({
               hasConnectionError: false,
               hasNoNewPasswordError: false,
               hasNoNewPasswordRepeatError: false,
               hasNoOldPasswordError: false,
               hasPasswordMatchError: false,
               hasCheckError: false,
               hasMatchError: false
          })
          if(this.state.newPassword!==this.state.newPasswordRepeat){
               this.setState({
                    hasPasswordMatchError: true
               });
          }else{
      
                    let passwordData = {
                         'userId': this.props.user.user_id,
                         'newPassword': this.state.newPassword,
                         'latest_phone': this.state.latest_phone,
                         'latest_email': this.state.latest_email
                    }
                    var formBody = [];
                    for (var property in passwordData) {
                    var encodedKey = encodeURIComponent(property);
                    var encodedValue = encodeURIComponent(passwordData[property]);
                    formBody.push(encodedKey + "=" + encodedValue);
                    }
                    formBody = formBody.join("&");
                  
                    this.props.dispatch(updateContactInfo(formBody));
                    this.props.user.latest_email = this.state.latest_email;
                    this.props.user.latest_phone = this.state.latest_phone;
                    this.props.router.push('settings-done');
          }
           
            
   }

     navigateTo(location){
          this.props.router.push(location)
     }

     render() {
        let error_message = "";

        if(this.state.hasError){
             error_message = (<div className="react-error-message"><strong>Sorry</strong> - Please make sure you complete at least one field.</div>);
        }
        if(this.state.hasPasswordMatchError){
          error_message = (<div className="react-error-message"><strong>Sorry</strong> - Your password don''t match.</div>);
        }

          return (
            <div className="settings-pane">
            <a onClick={ () => this.navigateTo('settings2') } className="settings-back">Back</a>
            <h2>Update Settings</h2>
            { error_message }
            <div className="settings-form-pane">
                 <input type="text" onChange={ this.setMobile.bind(this) } value={ this.state.latest_phone } placeholder="Mobile" />
                 <input type="text" onChange={ this.setEmail.bind(this) } value={ this.state.latest_email } placeholder="Email Address" />
                 <input type="password" onChange={ this.setNewPassword.bind(this) } value={ this.state.newPassword } placeholder="New password" />                 
                 <input type="password" onChange={ this.setNewPasswordRepeat.bind(this) } value={ this.state.newPasswordRepeat } placeholder="Re-type new password" />
            </div>
            <div className="button-pane">
                 <button onClick={ () => this.updateContactButton() } className="button-main button-block">Update Details</button>
            </div>
       </div>
          );
     }
}

function mapStateToProps(store){
    return {
         user: store.user
    };
}

export default withRouter(connect(mapStateToProps)(Settings2));
