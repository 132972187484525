import axios from "axios"
import UrlHelper from "./../url_helper"

export function downloadsFetch(user_id){

     return function(dispatch){

          dispatch( { type: 'FETCH_DOWNLOADS' });

          axios.get(UrlHelper.get_url_for_ajax('get_downloads/'+user_id))
               .then((response) => {
                    dispatch( { type: 'POPULATE_DOWNLOADS', payload: response.data })
               })
               .catch((err) => {
                    dispatch( { type: 'DOWNLOAD_ERROR_DOWNLOADS', payload: err })
               })

     };

}

export function myDownloadsFetch(user_id){

     return function(dispatch){

          dispatch( { type: 'FETCH_MY_DOWNLOADS' });

          axios.get(UrlHelper.get_url_for_ajax('get_my_downloads/'+user_id))
               .then((response) => {
                    dispatch( { type: 'POPULATE_MY_DOWNLOADS', payload: response.data })
               })
               .catch((err) => {
                    dispatch( { type: 'DOWNLOAD_ERROR_MY_DOWNLOADS', payload: err })
               });

     };

}

export function clearMyDownloads(user_id){

    return function(dispatch){

        axios.get(UrlHelper.get_url_for_ajax('basket-clear/'+user_id))
        .then((response) => {
            dispatch( { type: 'CLEAR_MY_DOWNLOADS_DONE', payload: response.data })
        })
        .catch((err) => {
            dispatch( { type: 'CLEAR_MY_DOWNLOADS_ERROR', payload: err })
        });

    };

}

export function downloadBasketMP3(user_id){

     return function(dispatch){

          dispatch( { type: 'DOWNLOAD_MP3' });

          axios.get(UrlHelper.get_url_for_ajax('basket-download-mp3/'+user_id))
               .then((response) => {
                    dispatch( { type: 'POPULATE_MY_DOWNLOADS', payload: response.data })
               })
               .catch((err) => {
                    dispatch( { type: 'DOWNLOAD_ERROR_MY_DOWNLOADS', payload: err })
               });

     };

}
