import React from 'react';

const SkipSection = ({reactSkipAction, showReactionFormAction, reactNotFeelingItAction}) => {
    return (<div className="react-skip">
        <p>You can download these mixes before completing this reaction or you can go ahead and complete the reaction.</p>
        <div className="form-button">
            <button onClick={reactSkipAction} className="button-main button-block">Download &amp; React Later</button>
        </div>
        <div className="form-button">
            <button onClick={showReactionFormAction} className="button-main button-block">Complete Reaction</button>
        </div>
        <div className="form-button">
            <button onClick={reactNotFeelingItAction} className="button-secondary button-block">Not for me</button>
        </div>
    </div>);
}

export default SkipSection;