import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { updateContactInfo } from './../actions/settingsActions';
import axios from 'axios';
import UrlHelper from './../url_helper';


class SettingsContactInfo extends Component {

     constructor(props){
          super(props);

          this.state = {
              isReady: false,
              resortOptions: [],
              hasError: false,
              firstName: props.user.firstname,
              lastName: props.user.lastname,
              djName: props.user.djname,
              street: props.user.address.street,
              streetTwo: props.user.address.street_2,
              town: props.user.address.town,
              county: props.user.address.county,
              postcode: props.user.address.post_code,
              mobile: props.user.mobile,
              email: props.user.email
          }

          // this.state = {
          //     isReady: false,
          //     resortOptions: [],
          //     hasError: false,
          //     firstName: '',
          //     lastName: '',
          //     djName: '',
          //     street: '',
          //     streetTwo: '',
          //     town: '',
          //     county: '',
          //     postcode: '',
          //     mobile: '',
          //     email: '',
          // }

     }

     componentDidMount() {
         axios.get(UrlHelper.get_url_for_ajax('list-resorts')).then((response)=>{
             this.setState({
                 isReady: true,
                 resortOptions: response.data
             })
         });
     }

     setFirstName(event){
          this.setState({
               firstName: event.target.value
          });
     }

     setLastName(event){
          this.setState({
               lastName: event.target.value
          });
     }

     setDjName(event){
          this.setState({
               djName: event.target.value
          });
     }

     setStreet(event){
          this.setState({
               street: event.target.value
          });
     }

     setStreetTwo(event){
          this.setState({
               streetTwo: event.target.value
          });
     }

     setTown(event){
          this.setState({
               town: event.target.value
          });
     }

     setCounty(event){
          this.setState({
               county: event.target.value
          });
     }

     setPostcode(event){
          this.setState({
               postcode: event.target.value
          });
     }

     setMobile(event){
          this.setState({
               mobile: event.target.value
          });
     }

     setEmail(event){
          this.setState({
               email: event.target.value
          });
     }

     updateContactButton() {
          let error = true,
              key = '',
              i = 0,
              fields = [
                   'firstName',
                   'lastName',
                   'djName',
                   'street',
                   'streetTwo',
                   'town',
                   'county',
                   'postcode',
                   'mobile',
                   'email',
              ];

              for(i=0; i<fields.length; i++){
                   key = fields[i];
                   if(this.state[key].length>1){
                        error = false;
                   }
              }

              if(error){
                   this.setState({
                        hasError: true
                   });
              } else {
                   console.log(this.props);
                   this.props.dispatch(updateContactInfo(this.state));
                   this.props.router.push('settings-done');
              }
     }



     navigateTo(location){
          this.props.router.push(location)
     }

     render() {


          let error_message = "";

          if(this.state.hasError){
               error_message = (<div className="react-error-message"><strong>Sorry</strong> - Please make sure you complete at least one field.</div>);
          }

          return (
               <div className="settings-pane">
                    <a onClick={ () => this.navigateTo('settings') } className="settings-back">Back</a>
                    <h2>Update Contact Info.</h2>
                    { error_message }
                    <div className="settings-form-pane">
                         <input type="text" onChange={ this.setFirstName.bind(this) } value={ this.state.firstName } placeholder="First Name" />
                         <input type="text" onChange={ this.setLastName.bind(this) } value={ this.state.lastName } placeholder="Last Name" />
                         <input type="text" onChange={ this.setDjName.bind(this) } value={ this.state.djName } placeholder="DJ Name" />
                         <hr />
                         <input type="text" onChange={ this.setStreet.bind(this) } value={ this.state.street } placeholder="Street" />
                         <input type="text" onChange={ this.setStreetTwo.bind(this) } value={ this.state.streetTwo } placeholder="" />
                         <input type="text" onChange={ this.setTown.bind(this) } value={ this.state.town } placeholder="Town" />
                         <input type="text" onChange={ this.setCounty.bind(this) } value={ this.state.county } placeholder="County" />
                         <input type="text" onChange={ this.setPostcode.bind(this) } value={ this.state.postcode } placeholder="Postcode" />
                         <hr />
                         <input type="text" onChange={ this.setMobile.bind(this) } value={ this.state.mobile } placeholder="Mobile" />
                         <input type="text" onChange={ this.setEmail.bind(this) } value={ this.state.email } placeholder="Email Address" />
                    </div>
                    <div className="button-pane">
                         <button onClick={ () => this.updateContactButton() } className="button-main button-block">Update Details</button>
                    </div>
               </div>
          );
     }
}

function mapStateToProps(store){
     return {
          user: store.user
     };
}

export default withRouter(connect(mapStateToProps)(SettingsContactInfo));
