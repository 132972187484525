import React, { Component } from 'react';
import { withRouter } from 'react-router';

import { setReactionTrack } from '../actions/reactionActions';

class trackDisplay extends Component {

     reactClick(evt) {
          this.props.dispatch(setReactionTrack(this.props));
          this.props.router.push('reaction');
     }

     downloadClick(evt) {
          this.props.router.push('downloads');
     }

     render (){

          let trackArtworkStyle = {};
          let trackOutput = "";

          if(this.props.artwork){
               trackArtworkStyle = {
                    backgroundImage: 'url('+this.props.artwork+')'
               };
          }

          if(this.props.type==="main"){
               trackOutput = (
                    <div onClick={this.reactClick.bind(this)} className="track-main" style={ trackArtworkStyle }>
                         <div className="track-main-details">
                              <h1>{ this.props.title }</h1>
                              <p>{ this.props.artist }</p>
                         </div>
                    </div>
               );
          } else {
               trackOutput = (
                    <div onClick={this.reactClick.bind(this)} className="track-sub" style={ trackArtworkStyle }>
                         <h2>{ this.props.track_title }</h2>
                         <p>{ this.props.artist }</p>
                    </div>
               );
          }

          return trackOutput;
     }
}

export default withRouter(trackDisplay);
