import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { reactionSaveTastemaker, reactionDownloadAndReactLater } from './../../actions/reactionActions';

import AudioPlayer from './../audio-player';

import analytics from "./../../helpers/analytics";

import SkipSection from './SkipSection';
import ErrorMessage from './ErrorMessage';
import ScoreSelector from './ScoreSelector';

const FormSection = ({ label, children }) => {
     return (<div>
               <div className="react-title">{ label }</div>
               <div className="react-form-row-text">{ children }</div>
             </div>)
}


class ReactionTastemaker extends Component {

     constructor (props){
          super(props);

          let rating_quick_button_active = new Array(5).fill(''),
              rating_club_score_active = new Array(5).fill(''),
              should_show_react_form = true;

          if(props.track.can_skip===true){
              should_show_react_form = false;
          }

          this.state = {
               react_fav_mix: "",
               react_personal_score: 10,
               react_club_score: 10,
               react_comment: "",
               react_radio_play: "",
               react_review: "",

               rating_quick_button_active: rating_quick_button_active,
               rating_club_score_active: rating_club_score_active,
               has_error: 0,

               should_show_react_form: should_show_react_form,

               download_tracks: []
          }
     }

     componentDidMount() {
         window.scrollTo(0, 0);
     }

     showReactionForm = () => {
         this.setState({
             should_show_react_form: true
         });
     }

     setRating = (rating) => {

          let rating_array_active = new Array(5).fill('');

          rating_array_active[rating] = 'active';

          this.setState({
               has_error: 0,
               react_personal_score: rating,
               rating_quick_button_active: rating_array_active
          });
     }

     setRatingBySelect = (e) => {
          console.log("Set Rating", e.target.value)
          this.setState({
               has_error: 0,
               react_personal_score: e.target.value
          })
     }

     setClubScoreBySelect = (e) => {
          console.log("Set Club Score", e.target.value)
          this.setState({
               has_error: 0,
               react_club_score: e.target.value
          });
     }

     setClubScore = (rating) => {

          let rating_array_active = new Array(5).fill('');

          rating_array_active[rating] = 'active';

          this.setState({
               has_error: 0,
               react_club_score: rating,
               rating_club_score_active: rating_array_active
          });
     }


     setComment = (event) => {
          this.setState({
               react_comment: event.target.value
          });
     }

     setRadioPlay = (event) => {
          this.setState({
               react_radio_play: event.target.value
          });
     }

     setReview = (event) => {
          this.setState({
               react_review: event.target.value
          });
     }

     setFavouriteTrack = (track_id) => {
          this.setState({
               react_fav_mix: track_id
          });
     }

     addTrackToDownloads = (track_id) => {
          let downloadTracks = this.state.download_tracks,
              trackIndex = downloadTracks.indexOf(track_id);

          if(trackIndex>-1){
               downloadTracks.splice(trackIndex, 1);
          } else {
               downloadTracks.push(track_id);
          }

          this.setState({
               download_tracks: downloadTracks,
          });

          console.log(downloadTracks);
     }

     reactSkip = () => {

         let reaction_data = {
              user_id: this.props.user.user_id,
              track_id: this.props.track.id,
              react_fav_mix: this.state.react_fav_mix,
              react_rating: this.state.react_personal_score,
              react_club_score: this.state.react_club_score,
              react_comment: this.state.react_comment,
              react_radio_play: this.state.react_radio_play,
              react_review: this.state.react_review,
              reaction_type: 'TASTEMAKER',
              download_tracks: this.state.download_tracks,
              score_ten: 1
         }

         this.props.dispatch(reactionDownloadAndReactLater(reaction_data));
         this.props.router.push('reaction-skipped');
     }

     reactDone = () => {

          let noError = true;

          if(this.state.react_personal_score<0){
               noError = false;
               this.setState({
                    has_error: 1,
               });
          }

          if(this.state.react_fav_mix.length<1){
               noError = false;
               this.setState({
                    has_error: 2,
               });
          }

          //No Error - Lets push the reaction
          if(noError){

               let reaction_data = {
                    user_id: this.props.user.user_id,
                    track_id: this.props.track.id,
                    react_fav_mix: this.state.react_fav_mix,
                    react_rating: this.state.react_personal_score,
                    react_club_score: this.state.react_club_score,
                    react_comment: this.state.react_comment,
                    react_radio_play: this.state.react_radio_play,
                    react_review: this.state.react_review,
                    reaction_type: 'TASTEMAKER',
                    download_tracks: this.state.download_tracks
               }

               analytics.track_reacted(this.props.track.id);

               console.log(reaction_data);

               this.props.dispatch(reactionSaveTastemaker(reaction_data));
               this.props.router.push('reaction-done');
          }

     }

     reactNotFeelingIt = () => {

          let reaction_data = {
               user_id: this.props.user.user_id,
               track_id: this.props.track.id,
               react_fav_mix: '',
               react_rating: 2,
               react_club_score: 2,
               react_comment: 'not for me',
               react_radio_play: '',
               react_review: '',
               reaction_type: 'TASTEMAKER',
               download_tracks: this.state.download_tracks
          }

          this.props.dispatch(reactionSaveTastemaker(reaction_data));
          this.props.router.push('reaction-done');

     }


     render () {

          let { track } = this.props,
              { has_error, should_show_react_form } = this.state,
              showSkip = track.can_skip ? true : false,
              error_message = "Please select how you feel about this track using the drop down below.";

          if(has_error===2){
               error_message = "Please select a favourite mix above.";
          }

          if(should_show_react_form===true){
               showSkip = false;
               analytics.track_listened(track.id);
          }


          return (
               <div>
                    <AudioPlayer audioTracks={ this.props.audioTracks } trackDetails={ this.props.trackDetails } dispatch={ this.props.dispatch } favTrackFunction={ this.setFavouriteTrack.bind(this) } downloadTrackFunction={ this.addTrackToDownloads.bind(this) } />
                    { has_error>0 && (<ErrorMessage message={error_message} />) }
                    { showSkip && (<SkipSection reactSkipAction={ this.reactSkip } showReactionFormAction={ this.showReactionForm } reactNotFeelingItAction={ this.reactNotFeelingIt } />) }
                    { should_show_react_form &&(<div>
                           <FormSection label="Club Score">
                                <ScoreSelector changeAction={ this.setClubScoreBySelect } />
                           </FormSection>
                           <FormSection label="Personal Score">
                                <ScoreSelector changeAction={ this.setRatingBySelect } />
                           </FormSection>
                           <FormSection label="Comment">
                                <textarea rows="3" onChange={ this.setComment } placeholder="Please enter a comment" value={ this.state.react_comment }></textarea>
                           </FormSection>
                           <FormSection label="Please enter any radio play details. Please leave blank if not applicable">
                                <textarea rows="3" onChange={ this.setRadioPlay } placeholder="Please enter any radio play details." value={ this.state.react_radio_play }></textarea>
                           </FormSection>
                           <FormSection label="Online mix support, reviews etc?  Please leave blank if not applicable">
                                <textarea rows="3" onChange={ this.setReview } placeholder="Please enter the direct link for any online mixes where you have played this track (i.e. Mixcloud, Soundcloud, Twitch etc), or websites where you have reviewed it." value={ this.state.react_review }></textarea>
                           </FormSection>
                           <div className="form-button">
                                <button onClick={ this.reactDone } className="button-main button-block">Submit Reaction</button>
                           </div>
                           <div className="form-button">
                                <button onClick={ this.reactNotFeelingIt } className="button-secondary button-block">Not for me</button>
                           </div>
                      </div>) }
               </div>
          );
     }
}

function mapStateToProps(store){
     return {
          user: store.user,
          reaction: store.reaction,
     };
}

export default withRouter(connect(mapStateToProps)(ReactionTastemaker));
