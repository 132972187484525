import React, { Component } from 'react';
import Loading from 'react-loading';

export default class LaodingScreen extends Component {

  render(){
    return(
      <div className="loading-screen">
        <Loading type='bars' color='#000000' />
        <p>Loading</p>
      </div>
    );
  }

}
