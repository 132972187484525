import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { setReactionTrack } from './../actions/reactionActions';

class ReactionSkiped extends Component {

     goReactNext(evt) {
          let route = 'start';
          if(this.props.tracks.length>0){
               this.props.dispatch(setReactionTrack(this.props.tracks[0]));
               route = 'reaction';
          }
          this.props.router.push(route);
     }

     goDownloads(evt) {
          this.props.router.push('downloads')
     }

     render() {

          let trackArtworkStyle = {};

          if(this.props.reaction_track.artwork){
               trackArtworkStyle = {
                    backgroundImage: 'url('+this.props.reaction_track.artwork+')'
               };
          }

          return (
               <div>
                    <div className="react-container">
                         <div className="react-main" style={ trackArtworkStyle }></div>
                    </div>
                    <div className="react-complete">
                         <h1>You will react later</h1>
                         <p>You have chosen to react to this track later, in the meantime you can downloaded the mixes from the downloads section.</p>
                         <button onClick={this.goReactNext.bind(this)} className="button-main button-block">Next Track</button>
                         <hr />
                         <button onClick={this.goDownloads.bind(this)} className="button-secondary button-block button-margin-bottom">Downloads</button>
                    </div>
               </div>
          );
     }
}

function mapStateToProps(store){
     return {
          reaction_track: store.reaction.reaction_track,
          tracks: store.tracks.tracks
     };
}

export default withRouter(connect(mapStateToProps)(ReactionSkiped));
