import React, { Component } from 'react';

// ------------------------------------------------------------
// Audio Track
// ------------------------------------------------------------
class AudioPlayerTrack extends Component {

     render (){

          let audioRowClass = "audio-player-row";

          if(this.props.activeClass){
               audioRowClass += " active ";
          }

          return (
               <div className={ audioRowClass }>
                    <button className="audio-player-play-track" onClick={this.props.onClick} >Play</button>
                    { this.props.track.name }
               </div>
          )
     }

}

// ------------------------------------------------------------
// Audio Track Player
// ------------------------------------------------------------
export default class audioPlayer extends Component {

     constructor(props) {
          super(props);
          this.state = {
               active_track: this.props.audioTracks[0],
               stream_url: this.props.audioTracks[0].stream_mp3
          }
     }

     // componentDidMount() {
     //   this.audio.setAttribute("controlsList","nodownload");
     // }

     // Change track
     playTrack(track){
          this.setState({ active_track: track })
          this.setState({ stream_url: track.stream_mp3 })

          let audioPlayerControl = document.getElementById('audioPlayerObject');
          if(audioPlayerControl!=null){
               audioPlayerControl.pause();
               audioPlayerControl.load();
          }
     }

     //Render Audio Player
     render(){

          const track = this.props.trackDetails;
          let AudioTracks = [];

          this.props.audioTracks.map((track, i) => {

               let activeTrackRowClass = "";

               if(track===this.state.active_track){
                    activeTrackRowClass = "active";
               }

               AudioTracks.push(
                    <div key={i} className="audio-player-track-container">
                         <AudioPlayerTrack key={i} onClick={ () => this.playTrack(track) } track={ track } activeClass={activeTrackRowClass} dispatch={this.props.dispatch} />
                         <div className='audio-player-fav-track'>
                              <input type="radio" id={ track.id } onClick={ () => { this.props.favTrackFunction(track.id) } } name="fav_track" value={ track.id } />
                              <label htmlFor={ track.id }>{ track.id }</label>
                         </div>
                         <div className="audio-player-download-track">
                              <input type="checkbox" id={ track.id+'_download' } onClick={ () => { this.props.downloadTrackFunction(track.id) } } name="download_track" value={ track.id } />
                              <label htmlFor={ track.id+'_download' }>{ track.id }</label>
                         </div>
                    </div>
               );

               return true;
          });

          return (
               <div className="audio-player">
                    <div className="audio-player-now-playing">
                         <h2>{ this.state.active_track.name }</h2>
                         <p>{ track.title } - { track.artist }</p>
                    </div>
                    <audio controls="controls" id="audioPlayerObject" autoPlay="true" controlsList="nodownload">
                         <source id="audioPlayerObjectMP3" src={ this.state.stream_url } type="audio/mpeg" />
                    </audio>
                    { AudioTracks }
               </div>
          );
     }
}
