import axios from "axios"
import UrlHelper from "./../url_helper"

export function trackFetch(user_id){

     return function(dispatch){

          dispatch( { type: 'FETCH_TRACKS' });

          axios.get(UrlHelper.get_url_for_ajax('get_tracks/'+user_id))
               .then((response) => {
                    dispatch( { type: 'POPULATE_TRACKS', payload: response.data })
               })
               .catch((err) => {
                    dispatch( { type: 'DOWNLOAD_ERROR_TRACKS', payload: err })
               })

     };
}

export function trackDownloadError(error_message){
     return {
          type: 'FETCH_TRACKS',
          payload: error_message,
     }
}

export function trackRemoveFromList(track){

     return {
          type: 'REMOVE_TRACK',
          payload: track
     }

}

export function trackPopulate(data){
     return {
          type: 'FETCH_TRACKS',
          payload: data
     }
}

export function trackDownloadStream() {
     return {
          type: 'DOWNLOAD_TRACK_STREAM',
          payload: {}
     }
}
