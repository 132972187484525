import React, { Component } from 'react';
import { withRouter } from 'react-router';

class SettingsViewClubs extends Component {

    navigateTo(location){
          this.props.router.push(location)
     }

     render() {
          return (
               <div className="settings-pane">
                    <a onClick={ () => this.navigateTo('settings') } className="settings-back">Back</a>
                    <h2>My Clubs</h2>
                    <table className="table">
                         <thead>
                              <tr>
                                   <th>Club Name</th>
                                   <th>Capacity</th>
                                   <th>Edit</th>
                              </tr>
                         </thead>
                         <tbody>
                              <tr>
                                   <td>Some Club</td>
                                   <td>300</td>
                                   <td><a onClick={ () => this.navigateTo('edit-club') }>Edit</a></td>
                              </tr>
                              <tr>
                                   <td>Some Club #2</td>
                                   <td>440</td>
                                   <td><a onClick={ () => this.navigateTo('edit-club') }>Edit</a></td>
                              </tr>

                         </tbody>
                    </table>

                    <div className="button-pane">
                         <button onClick={ () => this.navigateTo('add-club') } className="button-main button-block">Add Club</button>
                    </div>
               </div>
          );
     }
}

export default withRouter(SettingsViewClubs);
