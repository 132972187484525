import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { userLogOut } from './../actions/userActions';

class Menu extends Component {

     constructor() {
          super();
          this.state = { visible: false };
     }

     showMenu(){
          if(this.state.visible){
               this.setState({ visible: false });
          } else {
               this.setState({ visible: true });
          }
     }

     navigateTo(location){
          this.setState({ visible: false });
          this.props.router.push(location)
     }

     logOut(){
          this.props.dispatch(userLogOut());
          this.props.router.push('/')
     }

     render(){
          return ( 
               <div className={(this.state.visible ? "menu-visible menu" : "menu")}>
                    <div className="menu-close-button"><span onClick={ () => this.showMenu() } className="glyphicon glyphicon-menu-hamburger"></span></div>
                    <div>
                         <div className="menu-item" onClick={ () => this.navigateTo('app') }>Tracks</div>
                         <div className="menu-item" onClick={ () => this.navigateTo('outstanding') }>Outstanding Reactions</div>
                         <div className="menu-item" onClick={ () => this.navigateTo('priorities') }>Priorities</div>
                         <div className="menu-item" onClick={ () => this.navigateTo('downloads') }>Downloads</div>
                         <hr />
                         <div className="menu-item" onClick={ () => this.navigateTo('settings') }>Settings</div>
                         <hr />
                         <div className="menu-item" onClick={ () => this.logOut() }>Logout</div>
                    </div>
               </div>
          );
     }

}

export default withRouter(connect()(Menu));
