import React, { Component } from 'react';

export default class Search extends Component {

     constructor() {
          super();
          this.state = { visible: false, keyword: '' };
     }

     showSearch(){
          if(this.state.visible){
               this.setState({ visible: false });
               this.props.actionSearch('');
          } else {
               this.setState({ visible: true, keyword: '' });
               this.searchInput.focus();
          }
     }

     performSearch(e) {
         this.setState({
             keyword: e.target.value
         });
         this.props.actionSearch(e.target.value);
     }

     render(){

          return (
               <div>
                    <div className="search-button"><button className={(this.state.visible ? "button-top-search search-button-close" : "button-top-search")} onClick={ event => this.showSearch() }></button></div>
                    <div className={(this.state.visible ? "search-visible search" : "search")}>
                    <div><input ref={(input) => { this.searchInput = input; }}  placeholder="Search Tracks & Mixes" onChange={ this.performSearch.bind(this) } value={ this.state.keyword } /></div>
                    </div>
               </div>
          );
     }

}
