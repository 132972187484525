import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, IndexRoute, hashHistory } from 'react-router';
import { Provider } from 'react-redux';

import App from './components/app';
import Login from './components/login';
import Start from './components/start';
import Outstanding from './components/outstanding';
import Reaction from './components/reaction';
import ReactionDone from './components/reaction-done';
import ReactionSkiped from './components/reaction-skipped';

import Settings from './components/settings';
import Settings2 from './components/settings2';
import SettingsDone from './components/settings-done';
import SettingsViewClubs from './components/settings-view-clubs';
import SettingsAddClub from './components/settings-add-club';
import SettingsEditClub from './components/settings-edit-club';
import SettingsRadio from './components/settings-radio';
import SettingsResort from './components/settings-resort';
import SettingsContactInfo from './components/settings-contact-info';
import SettingsPassword from './components/settings-password';

import History from './components/history';
import Downloads from './components/downloads';
import DownloadsAll from './components/downloads-all';
import Priorities from './components/priorities';
import Player from './components/player';
import Logout from './components/logout';

import store from './store';

const DomAppContainer = document.getElementById('app');
const quickLink = DomAppContainer.dataset.ql;

//Raven.config('https://4f582c58df77491da071fa049388efd2@sentry.io/140654').install();

//onUpdate={() => window.scrollTo(0, 0)}

ReactDOM.render(
     <Provider store={store}>
          <Router history={hashHistory}>
          <Route path="/" component={Login} quickLinkCode={ quickLink }></Route>
              <Route path="/app" component={App}>
                   <IndexRoute component={Start} />
                   <Route path="/add-club" component={SettingsAddClub} />
                   <Route path="/edit-club" component={SettingsEditClub} />
                   <Route path="/contact-info" component={SettingsContactInfo} />
                   <Route path="/downloads" component={Downloads} />
                   <Route path="/downloads-all" component={DownloadsAll} />
                   <Route path="/history" component={History} />
                   <Route path="/outstanding" component={Outstanding} />
                   <Route path="/logout" component={Logout} />
                   <Route path="/password" component={SettingsPassword} />
                   <Route path="/player" component={Player} />
                   <Route path="/priorities" component={Priorities} />
                   <Route path="/radio" component={SettingsRadio} />
                   <Route path="/resort" component={SettingsResort} />
                   <Route path="/reaction" component={Reaction} />
                   <Route path="/reaction-done" component={ReactionDone} />
                   <Route path="/reaction-skipped" component={ReactionSkiped} />
                   <Route path="/settings" component={Settings2} />
                   <Route path="/settings-done" component={SettingsDone} />
                   <Route path="/view-clubs" component={SettingsViewClubs} />
              </Route>
          </Router>
     </Provider>
     , DomAppContainer);
