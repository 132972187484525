import axios from "axios"
import UrlHelper from "./../url_helper"
import analytics from "./../helpers/analytics"
import jsCookie from "js-cookie"

//Login Actions
export function userLogin(login_object){
     return function(dispatch){

          dispatch( { type: 'START_LOGIN' });
          axios.post(UrlHelper.get_url_for_ajax('do_login'), login_object)
               .then((response) => {
                    if(response.data.login_ok){
                         analytics.set_dj_id(response.data.user_id);
                         userSetSession(response.data.user_id);
                         jsCookie.set("user",response.data)
                         jsCookie.set("user_id",response.data.user_id)
                         jsCookie.set("username",response.data.username)
                         dispatch( { type: 'LOGIN_OK', payload: response.data })
                    } else {
                         dispatch( { type: 'LOGIN_FAIL', payload: response.data })
                    }
               })
               .catch((err) => {
                    dispatch( { type: 'LOGIN_ERROR', payload: err })
               })
     }
}

//Login Actions
export function userLoginQl(login_object){
     return function(dispatch){

          dispatch( { type: 'START_LOGIN' });
          axios.post(UrlHelper.get_url_for_ajax('do_login_ql'), login_object)
               .then((response) => {
                   if(response.data.login_ok){
                         analytics.set_dj_id(response.data.user_id);
                         dispatch( { type: 'LOGIN_QL_OK', payload: response.data })
                    } else {
                         dispatch( { type: 'LOGIN_QL_FAIL', payload: response.data })
                    }
               })
               .catch((err) => {
                    dispatch( { type: 'LOGIN_ERROR', payload: err })
               })
     }
}

export function userSetSession(session_id){
     return {
          type: 'USER_SET_SESSION',
          payload: session_id
     }
}

export function userDestroySession(){
     return {
          type: 'USER_DESTROY_SESSION'
     }
}

export function userLogOut(){
     return {
          type: 'USER_LOGOUT'
     }
}

//Settings Actions
export function userChangeContactDetails(contactDetails){
     return function(dispatch){
          const config = {
               headers: {
                 'Content-Type': 'application/x-www-form-urlencoded'
               }
             }
          axios.post(UrlHelper.get_url_for_ajax('save-contact-details'), contactDetails,config)
               .then((response) => {
                    if(response.data.save_ok){
                         dispatch( { type: 'USER_CHANGE_OK', payload: response.data })
                    } else {
                         dispatch( { type: 'USER_CHANGE_FAIL', payload: response.data })
                    }
               })
               .catch((err) => {
                    dispatch( { type: 'USER_CHANGE_ERROR', payload: err })
               })
     }
}


export function userChangePassword(password){
     return function(dispatch){

          axios.post(UrlHelper.get_url_for_ajax('save-password'), password)
               .then((response) => {
                    if(response.data.save_ok){
                         dispatch( { type: 'USER_PASSWORD_OK', payload: response.data })
                    } else {
                         dispatch( { type: 'USER_PASSWORD_FAIL', payload: response.data })
                    }
               })
               .catch((err) => {
                    dispatch( { type: 'USER_CHANGE_ERROR', payload: err })
               })
     }
}
