import axios from "axios"
import UrlHelper from "./../url_helper"

export function priorityFetch(user_id){

     return function(dispatch){

          dispatch( { type: 'FETCH_PRIORITIES' });

          axios.get(UrlHelper.get_url_for_ajax('get_priorities'))
               .then((response) => {
                    dispatch( { type: 'POPULATE_PRIORITIES', payload: response.data })
               })
               .catch((err) => {
                    dispatch( { type: 'DOWNLOAD_ERROR_PRIORITIES', payload: err })
               })

     };

}
