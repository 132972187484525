import React, { Component } from 'react';
import { connect } from 'react-redux';

import LoadingScreen from './loading-screen';
import DownloadsNav from './downloads-nav';
import DownloadsItem from './downloads-item';

import { downloadsFetch } from './../actions/downloadActions';

class DownloadsAll extends Component {

     componentWillMount() {
          this.props.dispatch(downloadsFetch(this.props.user.user_id));
     }

     renderDownloads(){

          let downloadContent = (<LoadingScreen />);

          if(this.props.downloads.downloads_available){

               let downloadItemsList = [];
               this.props.downloads.downloads.map((download, i) => {

                   console.log(download);

                    downloadItemsList.push(<DownloadsItem
                                        key={i}
                                        trackId={ download.id }
                                        title={download.title}
                                        artist={download.artist}
                                        artwork={download.artwork_react}
                                        streams={download.streams}
                                        downloads={download.downloads}
                                        />);
                    return true;
               });

               downloadContent = (<div className="downloads">{ downloadItemsList }</div>);

          }


          return (
               <div>
                    <DownloadsNav activeTab='all_downloads' />
                    <div className='downloads-details'>This section contains all the available downloads.</div>
                    { downloadContent }
               </div>
          );

     }


     render() {
          return (<div>{ this.renderDownloads() }</div>);
     }
}

function mapStateToProps(store){
     return {
          user: store.user,
          downloads: store.downloads,
     };
}


export default connect(mapStateToProps)(DownloadsAll);
