import axios from 'axios';

import urlHelper from './../url_helper';

export default {

    dj_id: '',

    send_analytics: function(action, track_id) {
        axios(urlHelper.get_url_for_ajax('analytics/'+action+"/"+this.dj_id+"/"+track_id)).then(response => {
            console.log(response.data);
        }).catch(err => {
            console.log(err);
        })
    },

    set_dj_id: function(dj_id) {
        this.dj_id = dj_id;
    },

    track_viewed: function(track_id) {
        this.send_analytics('view', track_id);
    },

    track_listened: function(track_id) {
        this.send_analytics('listen', track_id);
    },

    track_downloaded: function(track_id) {
        this.send_analytics('download', track_id);
    },

    track_reacted: function(track_id) {
        this.send_analytics('react', track_id);
    }
}
