import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { updateRadio } from './../actions/settingsActions';

class SettingsRadio extends Component {

     constructor(props){
          super(props);
          this.state = {
               radioStation: "",
               broadcastArea: "",
               audience: ""
          }
     }

     updateRadioStation(event){
          this.setState({ radioStation: event.target.value });
     }

     updateBroadcateArea(event){
          this.setState({ broadcast_area: event.target.value });
     }

     updateAudience(event){
          this.setState({ audience: event.target.value });
     }

     saveRadio(){
          let error = true,
              key = '',
              i = 0,
              fields = [
                   'radioStation',
                   'broadcastArea',
                   'audience',
              ];

              for(i=0; i<fields.length; i++){
                   key = fields[i];
                   if(this.state[key].length>1){
                        error = false;
                   }
              }

              if(error){
                   this.setState({
                        hasError: true
                   });
              } else {
                   this.props.dispatch(updateRadio(this.state));
                   this.props.router.push('settings-done');
              }
     }


     navigateTo(location){
          this.props.router.push(location)
     }


     render() {

          let error_message = "";

          if(this.state.hasError){
               error_message = (<div className="react-error-message"><strong>Sorry</strong> - Please make sure you complete at least one field.</div>);
          }


          return (
               <div className="settings-pane">
                    <a onClick={ () => this.navigateTo('settings') } className="settings-back">Back</a>
                    <h2>My Radio</h2>
                    { error_message }
                    <div className="settings-form-pane">
                         <input type="text" placeholder="Radio Station" value={ this.state.radioStation } onChange={ this.updateRadioStation.bind(this) } />
                         <input type="text" placeholder="Broadcast Area" value={ this.state.broadcastArea } onChange={ this.updateBroadcateArea.bind(this) } />
                         <input type="text" placeholder="RAJAR Audience" value={ this.state.audience } onChange={ this.updateAudience.bind(this) } />
                    </div>
                    <div className="button-pane">
                         <button onClick={ ()=> this.saveRadio() } className="button-main button-block">Update Details</button>
                    </div>
               </div>
          );
     }
}

export default withRouter(connect()(SettingsRadio));
