import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingScreen from './loading-screen';
import TrackDisplay from './track-display';

import { trackFetch } from "../actions/trackActions";

class Start extends Component {

     componentWillMount(){
          if(this.props.tracks_available===false){
               this.props.dispatch(trackFetch(this.props.user.user_id));
          }
     }

     renderTracks(){
          if(this.props.tracks_available){

               let tracksLayout = [];

               this.props.tracks.map((track, i) => {
                    console.log(track);
                    if(track['is_outstanding']===true){
                        if(i===0){
                             tracksLayout.push(<TrackDisplay key={i} {...track}  history={this.props.history} dispatch={ this.props.dispatch } type='main' />);
                        }  else {
                             tracksLayout.push(<TrackDisplay key={i} {...track}  history={this.props.history} dispatch={ this.props.dispatch } />);
                        }
                    }
                    return true;
               });

               return (
                    <div className="track-container">
                         <div className="subtrack-section">
                              { tracksLayout }
                         </div>
                    </div>
               );

          } else {
               return (<LoadingScreen />);
          }

     }



     render() {

          return (<div>{ this.renderTracks() }</div>);
     }
}

function mapStateToProps(store){
     return {
          tracks_available: store.tracks.tracks_available,
          tracks: store.tracks.tracks,
          user: store.user,
          download_error: store.tracks.tracks_download_error,
     };
}

export default connect(mapStateToProps)(Start);
