/* ------------------------------------------------------------------------
     History not required is the same as downloads
------------------------------------------------------------------------ */

import React, { Component } from 'react';
import { connect } from 'react-redux';

import LoadingScreen from './loading-screen';

import { historyFetch } from './../actions/historyActions';

class History extends Component {

     componentWillMount() {
          this.props.dispatch(historyFetch(this.props.user.user_id));
     }

     renderHistory(){

          if(this.props.history.history_available){


          } else {
               return (<LoadingScreen />);
          }
     }

     render(){
          return (<div>{ this.renderHistory() }</div>);
     }

}

function mapStateToProps(store){
     return {
          user: store.user,
          history: store.history,
     };
}

export default connect(mapStateToProps)(History);
