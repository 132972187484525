const defaults = {
     firstname: '',
     lastname: '',
     djname: '',
     user_id: '',
     session_id: '',
     username: '',
     address: {},
     email: '',
     resort: '',
     mobile: '',
     user_signed_in: false,
     started_login: false,
     failed_login: false,
     login_error: '',
     quikLinkTrack: '',
     quikLinkReactTrack: '',
     latest_phone:'',
     latest_email:''
}

const userReducer = (state = defaults, action) => {

     switch(action.type){

          //User Login
          case "START_LOGIN": {
               state = {...state,
                    user_signed_in: false,
                    started_login: true,
                    failed_login: false
               };
               break;
          }

          case "LOGIN_OK": {
               state = {...state,
                    user_signed_in: true,
                    started_login: false,
                    failed_login: false,
                    firstname: action.payload.firstname,
                    lastname: action.payload.lastname,
                    djname: action.payload.djname,
                    user_id: action.payload.user_id,
                    session_id: action.payload.session_id,
                    username: action.payload.username,
                    address: action.payload.address,
                    resort: action.payload.resort,
                    mobile: action.payload.mobile,
                    email: action.payload.email,
                    latest_phone:action.payload.latest_phone,
                    latest_email:action.payload.latest_email
               };
               break;
          }

          case "LOGIN_FAIL": {
               state = {...state,
                    started_login: false,
                    failed_login: true
               };
               break;
          }

          case "LOGIN_QL_OK": {
               state = {...state,
                    user_signed_in: true,
                    started_login: false,
                    failed_login: false,
                    ql_link_fail: false,
                    firstname: action.payload.firstname,
                    lastname: action.payload.lastname,
                    djname: action.payload.djname,
                    user_id: action.payload.user_id,
                    session_id: action.payload.session_id,
                    username: action.payload.username,
                    address: action.payload.address,
                    resort: action.payload.resort,
                    mobile: action.payload.mobile,
                    email: action.payload.email,
                    quikLinkTrack: action.payload.quikLinkTrack,
                    quikLinkReactTrack: '',
                    latest_phone: action.payload.latest_phone,
                    latest_email: action.payload.latest_email
               };
               break;
          }

          case "LOGIN_QL_FAIL": {
               state = {...state,
                    started_login: false,
                    failed_login: true,
                    ql_link_fail: true
               };
               break;
          }

          case "QL_SET_TRACK": {
               state = {...state,
                    quikLinkReactTrack: action.payload
               };
               break;
          }

          case "LOGIN_ERROR": {
               state = {...state,
                    started_login: false,
                    failed_login: true,
                    login_error: action.payload
               };
               break;
          }

          case "USER_SET_SESSION": {
               state = {...state, session_id: action.payload};
               break;
          }

          case "USER_DESTROY_SESSION": {
               state = {...state, session_id: ""};
               break;
          }

          case "USER_LOGOUT": {
               state = {...state,
                    user_signed_in: false,
                    started_login: false,
                    failed_login: false,
                    firstname: "",
                    lastname: "",
                    djname: "",
                    user_id: "",
                    session_id: "",
                    username: "",
                    address: ""
               }
               break;
          }

          //Chnage User Details
          case "USER_CHANGE_OK": {
               state = {...state,
                     firstname: action.payload.firstname,
                     lastname: action.payload.lastname,
                     djname: action.payload.djname,
                     };
               break;
          }

          case "USER_CHANGE_FAIL": {
               //Probably Do Something Here...
               break;
          }

          case "USER_CHANGE_ERROR": {
               //Probably do
               break;
          }

          case "SETTINGS_UPDATE_RESORT": {
              state = {...state,
                    resort: action.payload.resort,
                    };
              break;
          }

          default: {
              break;
          }

     }

     return state;
}

export default userReducer;
