const defaults = {
     react_ready: false,
     reaction_track: null,
     react_type: 'tastemaker',
     react_like: null,
     react_club_score: -1,
     react_personal_score: -1,
     react_favourite_mix: null,
     react_radio_play: "",
     react_press_cover: "",
     react_reaction: "",
     react_fav_mix: "",
}

const reactionReducer = (state = defaults, action) => {

     switch(action.type){
          case "REACTION_REACT_TRACK": {
               state = {...state,
                    reaction_track: action.payload,
                    react_ready: true,
                    react_like: null,
                    react_club_score: -1,
                    react_personal_score: -1,
                    react_favourite_mix: null,
                    react_radio_play: '',
                    react_press_cover: '',
                    react_reaction: '',
                    };
               break;
          }
          case "REACTION_SET_FAVOURITE_MIX": {
               state = {...state,
                    react_fav_mix: action.payload.mix_id,
               }
               break;
          }
          case "SAVE_TASTEMAKER_START": {
               break;
          }
          case "REACTION_SAVE_TASTEMAKER_DONE": {
               break;
          }
          case "REACTION_SAVE_TASTEMAKER_ERROR": {
               console.log(action.payload);
               break;
          }
          case "REACTION_SAVE_TASTEMAKER": {
               state = {...state,
                    react_like: action.payload.react_like,
                    react_reaction: action.payload.reaction,
               };
               break;
          }
          case "REACTION_SAVE_TASTEMAKER_DOWNLOAD_SKIPPED": {
               state = {...state,
                    react_like: action.payload.react_like,
                    react_reaction: action.payload.reaction,
               };
               break;
          }
          case "REACTION_SAVE_RADIO": {
               state = {...state,
                    react_club_score: action.payload.react_club_score,
                    react_personal_score: action.payload.react_personal_score,
                    react_favourite_mix: action.payload.react_favourite_mix,
                    react_radio_play: action.payload.react_radio_play,
                    react_press_cover: action.payload.react_press_cover,
                    react_reaction: action.payload.react_reaction,
               };
               break;
          }
          default: {
              break;
          }

     }

     return state;
}

export default reactionReducer;
