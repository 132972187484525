import React, { Component } from 'react';
import { connect } from 'react-redux';

import LoadingScreen from './loading-screen';
import DownloadsNav from './downloads-nav';
import DownloadsItem from './downloads-item';
import UrlHelper from "./../url_helper"

import { myDownloadsFetch, clearMyDownloads } from './../actions/downloadActions';

class Downloads extends Component {

    constructor() {
        super();
        this.state = {
            displayWarning: false
        };
    }

     componentWillMount() {
          this.props.dispatch(myDownloadsFetch(this.props.user.user_id));
     }

     downloadAllMp3(){
          window.location.href = UrlHelper.get_url_for_ajax('basket-download-mp3/'+this.props.user.user_id);
     }

     downloadAllWav(){
          window.location.href = UrlHelper.get_url_for_ajax('basket-download-wav/'+this.props.user.user_id);
     }

     clearDownloads(){
         this.setState({
             displayWarning: true
         });
         this.render();
     }

     clearDownloadsOk(){
         this.setState({
             displayWarning: false
         });
         this.props.dispatch(clearMyDownloads(this.props.user.user_id));
         this.render();
     }

     clearDownloadsCancel(){
         this.setState({
             displayWarning: false
         });
         this.render();
     }

     renderDownloads(){

          let downloadContent = (<LoadingScreen />);
          let warningScreen = "";
          let downloadButtons = "";

          if(this.props.myDownloads.downloads_available){

               console.log(this.props);

               let downloadItemsList = [];
               this.props.myDownloads.downloads.map((download, i) => {
                    downloadItemsList.push(<DownloadsItem
                                        key={i}
                                        trackId={ download.id }
                                        title={download.title}
                                        artist={download.artist}
                                        artwork={download.artwork_react}
                                        streams={download.streams}
                                        downloads={download.downloads}
                                        />);

                    return true;
               });

               if(downloadItemsList.length>0){
                   downloadContent = (<div className="downloads">{ downloadItemsList }</div>);

                   downloadButtons = (<div className='layout-download-buttons'>
                                        <button className="pull-left" onClick={ this.downloadAllMp3.bind(this) }><span className="glyphicon glyphicon-circle-arrow-down"></span> Download All <strong>MP3</strong></button>
                                        <button className="pull-left" onClick={ this.downloadAllWav.bind(this) }><span className="glyphicon glyphicon-circle-arrow-down"></span> Download All <strong>WAV</strong></button>
                                        <button onClick={ this.clearDownloads.bind(this) }><span className="glyphicon glyphicon-remove"></span> <strong>Clear</strong> My Downloads</button>
                                   </div>);
               } else {
                   downloadContent = (<div className="downloads-none">No downloads presently, you can add mixes to 'My Downloads' whilst you complete reactions.</div>);
               }


          }

          if(this.state.displayWarning){
              warningScreen = (<div className="downloads-warning-overlay">
                                  <p>Are you sure you want to clear your downloads?</p>
                                  <button onClick={ this.clearDownloadsOk.bind(this) }>OK</button>
                                  <button onClick={ this.clearDownloadsCancel.bind(this) }>Cancel</button>
                              </div>);
          }

          return (
               <div>
                    { warningScreen }
                    <DownloadsNav activeTab='my_downloads' />
                    <div className='downloads-details-my-downloads'>You can now choose specific mixes to download as you complete a reactions, simply click on the icon shown on the left to add a mix to your 'My Downloads'.  You can also download all your mixes in a single zip bundle using the 'Download All' buttons below. </div>
                    { downloadButtons }
                    { downloadContent }
               </div>
          );

     }


     render() {
          return (<div>{ this.renderDownloads() }</div>);
     }
}

function mapStateToProps(store){
     return {
          user: store.user,
          myDownloads: store.myDownloads,
     };
}


export default connect(mapStateToProps)(Downloads);
