import axios from "axios"
import UrlHelper from "./../url_helper"
import analytics from "./../helpers/analytics";

export function setReactionTrack(track){
     analytics.track_viewed(track.id);
     return {
          type: 'REACTION_REACT_TRACK',
          payload: track
     }
}

export function setFavouriteTrack(mix_id){
     console.log(mix_id);
     return {
          type: 'REACTION_SET_FAVOURITE_MIX',
          payload: mix_id
     }
}

export function reactionSaveTastemaker(reaction_data){

     return function(dispatch){

          dispatch({ type: 'SAVE_TASTEMAKER_START' });

          axios.post(UrlHelper.get_url_for_ajax('save-reaction'), reaction_data)
               .then((response) => {
                    dispatch( { type: 'REACTION_SAVE_TASTEMAKER_DONE', payload: response.data })
                    dispatch( { type: 'REMOVE_TRACK', payload: reaction_data.track_id });
               })
               .catch((err) => {
                    dispatch( { type: 'REACTION_SAVE_TASTEMAKER_ERROR', payload: err })
               })

     };
}

export function reactionSaveRadio(reaction_data){

     return {
          type: 'RACTION_SAVE_RADIO',
          payload: reaction_data
     }
}

export function reactionDownloadAndReactLater(reaction_data){

    return function(dispatch) {

        axios.post(UrlHelper.get_url_for_ajax('skip-reaction'), reaction_data)
        .then((response) => {
            dispatch( { type: 'REACTION_SAVE_TASTEMAKER_DOWNLOAD_SKIPPED', payload: response.data })
            dispatch( { type: 'SKIP_TRACK', payload: reaction_data.track_id });
        })
        .catch((err) => {
            dispatch( { type: 'REACTION_SAVE_TASTEMAKER_ERROR', payload: err })
        })

    };

}
