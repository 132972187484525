import React from 'react'

const ScoreSelector = ({ changeAction }) => {
    const score = [
        { key : "10", value: "10 - Great" },
        { key : "9" , value: "9 " },
        { key : "8" , value: "8 " },
        { key : "7" , value: "7 " },
        { key : "6" , value: "6 " },
        { key : "5" , value: "5 - OK" },
        { key : "4" , value: "4 " },
        { key : "3" , value: "3 " },
        { key : "2" , value: "2 " },
        { key : "1" , value: "1 - Bad" },
    ]
    
    return (<select onChange={ changeAction} >
                { score.map(({ key, value }) => (<option value={ key }>{ value }</option>))}
            </select>)
}

export default ScoreSelector;