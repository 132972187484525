import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { updateResort } from './../actions/settingsActions';
import axios from 'axios';
import UrlHelper from './../url_helper';

class SettingsResort extends Component {

     constructor(props){
          super(props);
          console.log(props.user);
          this.state = {
               isReady: false,
               resortOptions: [],
               resort: props.user.resort
          }

          console.log(this.state.resort);
     }

     componentDidMount() {
         axios.get(UrlHelper.get_url_for_ajax('list-resorts')).then((response)=>{
             this.setState({
                 isReady: true,
                 resortOptions: response.data
             })
         });
     }

     updateResort(event){
          this.setState({ resort: event.target.value });
     }

     saveResort(){
         let updateObject = {
             user_id: this.props.user.user_id,
             resort: this.state.resort
         };

         this.props.dispatch(updateResort(updateObject));
         this.props.router.push('settings-done');
     }


     navigateTo(location){
          this.props.router.push(location)
     }


     render() {

         let resortOptions = "";

         if(this.state.isReady){
             resortOptions = this.state.resortOptions.map((option, index)=>{
                 return (<option key={index} value={option.id}>{option.resort}</option>);
             });
         }


          return (
               <div className="settings-pane">
                    <a onClick={ () => this.navigateTo('settings') } className="settings-back">Back</a>
                    <h2>Resort</h2>
                    <div className="settings-form-pane">
                        <p>Please select your current resort location in the field below. </p>
                        <select value={ this.state.resort } onChange={ this.updateResort.bind(this) }>
                           { resortOptions }
                        </select>
                    </div>
                    <div className="button-pane">
                         <button onClick={ ()=> this.saveResort() } className="button-main button-block">Update Resort</button>
                    </div>
               </div>
          );
     }
}

function mapStateToProps(store){
     return {
          user: store.user
     };
}

export default withRouter(connect(mapStateToProps)(SettingsResort));
