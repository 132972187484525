import axios from "axios"
import UrlHelper from "./../url_helper"

export function historyFetch(user_id){

     return function(dispatch){

          dispatch( { type: 'FETCH_HISTORY' });

          axios.get(UrlHelper.get_url_for_ajax('get_history/'+user_id))
               .then((response) => {
                    dispatch( { type: 'POPULATE_HISTORY', payload: response.data })
               })
               .catch((err) => {
                    dispatch( { type: 'DOWNLOAD_ERROR_HISTORY', payload: err })
               })

     };

}
