import React, { Component } from 'react'

import analytics from "./../helpers/analytics";


export default class DownloadsItem extends Component {

     downloadTrack(url){
         analytics.track_downloaded(this.props.trackId);
          //this.props.downloads.mp3_file
          //this.props.downloads.wav_file
          window.location.href = url;
     }

     downloadBundle(url){
         analytics.track_downloaded(this.props.trackId);
          //this.props.downloads.wav_bundle
          //this.props.downloads.mp3_bundle
          window.location.href = url;
     }

     render() {

          //Get Album Artwork
          var trackArtworkStyle = {};
          if(this.props.artwork){
               trackArtworkStyle = {
                    backgroundImage: 'url('+this.props.artwork+')'
               };
          }

          //Generate Download List
          var fileDownloadsList = [];
          this.props.downloads.map((download, i) => {
               fileDownloadsList.push(
                    <div key={i} className="downloads-row-file">
                         <button onClick={ this.downloadTrack.bind(this, download.mp3_file) }>MP3</button>
                         <button onClick={ this.downloadTrack.bind(this, download.wav_file) }>WAV</button>
                         <h3>{ download.name }</h3>
                    </div>
               );
               return true;
          });

          return (
               <div>
                    <div className="downloads-row" style={ trackArtworkStyle }>
                         <h2>{ this.props.title }</h2>
                         <p>{ this.props.artist }</p>
                         <div className="download-detail">

                         </div>
                    </div>
                    <div className="downloads-files">
                         { fileDownloadsList }
                    </div>
                    <span className="clearfix"></span>
               </div>
          );


     }

}
