import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import axios from 'axios';
import UrlHelper from "./../url_helper";

import { changePassword } from './../actions/settingsActions';

class SettingsPassword extends Component {

     constructor(props){
          super(props);

          this.state = {
               hasConnectionError: false,
               hasNoNewPasswordError: false,
               hasNoNewPasswordRepeatError: false,
               hasNoOldPasswordError: false,
               hasPasswordMatchError: false,
               hasCheckError: false,
               hasMatchError: false,
               currentPassword: '',
               newPassword: '',
               newPasswordRepeat: ''
          }
     }

     navigateTo(location){
          this.props.router.push(location)
     }

     setCurrentPassword(event){
          this.setState({
               currentPassword: event.target.value
          })
     }

     setNewPassword(event){
          this.setState({
               newPassword: event.target.value
          })
     }

     setNewPasswordRepeat(event){
          this.setState({
               newPasswordRepeat: event.target.value
          })
     }


     changePassword() {

          var self = this;

          this.setState({
               hasConnectionError: false,
               hasNoNewPasswordError: false,
               hasNoNewPasswordRepeatError: false,
               hasNoOldPasswordError: false,
               hasPasswordMatchError: false,
               hasCheckError: false,
               hasMatchError: false
          })

          if(this.state.currentPassword.length<1){
               this.setState({
                    hasNoOldPasswordError: true
               });
          } else if(this.state.newPassword.length<1){
               this.setState({
                    hasNoNewPasswordError: true
               });
          } else if(this.state.newPasswordRepeat.length<1){
               this.setState({
                    hasNoNewPasswordRepeatError: true
               });

          } else if(this.state.newPassword!==this.state.newPasswordRepeat){
               this.setState({
                    hasPasswordMatchError: true
               });
          } else {
               axios.post(UrlHelper.get_url_for_ajax('check-password'), {
                         userId: this.props.user.user_id,
                         password: this.state.currentPassword,
                    }).then((response) => {
                         var data = response.data;
                         if(data.error===true){
                              this.setState({
                                   hasMatchError: true
                              });
                         } else {
                              let passwordData = {
                                   userId: this.props.user.user_id,
                                   newPassword: this.state.newPassword
                              }

                              this.props.dispatch(changePassword(passwordData));
                              this.props.router.push('settings-done');
                         }
                    }).catch((err) => {
                         self.state.hasCheckError = true;
                    });
          }


     }

     render() {

          let error_message = "";

          if(this.state.hasNoOldPasswordError){
               error_message = (<div className="react-error-message"><strong>Sorry</strong> - Please enter your old password.</div>);
          }

          if(this.state.hasNoNewPasswordError){
               error_message = (<div className="react-error-message"><strong>Sorry</strong> - Please enter a new password.</div>);
          }

          if(this.state.hasNoNewPasswordRepeatError){
               error_message = (<div className="react-error-message"><strong>Sorry</strong> - Please repeat your new password below.</div>);
          }

          if(this.state.hasPasswordMatchError){
               error_message = (<div className="react-error-message"><strong>Sorry</strong> - Your password don''t match.</div>);
          }

          if(this.state.hasMatchError){
               error_message = (<div className="react-error-message"><strong>Sorry</strong> - Your current password is wrong.</div>);
          }

          return (
               <div className="settings-pane">
                    <a onClick={ () => this.navigateTo('settings') } className="settings-back">Back</a>
                    <h2>Password</h2>
                    { error_message }
                    <div className="settings-form-pane">
                         <input type="password" onChange={ this.setCurrentPassword.bind(this) } value={ this.state.currentPassword } placeholder="Current password" />
                         <input type="password" onChange={ this.setNewPassword.bind(this) } value={ this.state.newPassword } placeholder="New password" />
                         <input type="password" onChange={ this.setNewPasswordRepeat.bind(this) } value={ this.state.newPasswordRepeat } placeholder="Re-type new password" />
                    </div>
                    <div className="button-pane">
                         <button onClick={ () => this.changePassword() } className="button-main button-block">Update Password</button>
                    </div>
               </div>
          );
     }
}


function mapStateToProps(store){
     return {
          user: store.user,
     };
}

export default withRouter(connect(mapStateToProps)(SettingsPassword));
