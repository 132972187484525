import { combineReducers } from 'redux';

import user from './reducers/user';
import tracks from './reducers/track';
import reaction from './reducers/reaction';
import downloads from './reducers/download';
import myDownloads from './reducers/myDownload';
import history from './reducers/history';
import priorities from './reducers/priorities';
import settings from './reducers/settings';

export default combineReducers({
     user: user,
     tracks: tracks,
     reaction: reaction,
     downloads: downloads,
     myDownloads: myDownloads,
     history: history,
     priorities: priorities,
     settings: settings
});
