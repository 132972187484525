import React, { Component } from 'react';
import { withRouter } from 'react-router';

class SettingsEditClub extends Component {

     navigateTo(location){
          this.props.router.push(location)
     }

     render() {
          return (
               <div className="settings-pane">
               <a onClick={ () => this.navigateTo('view-clubs') } className="settings-back">Back</a>
               <h2>Edit Club</h2>
               <div className="settings-form-pane">
               <input type="password" placeholder="Night Name" />
               <input type="password" placeholder="Club Name" />
               <input type="password" placeholder="Capacity" />
               <input type="password" placeholder="Club Town" />
               <select>
               <option value="">London</option>
               </select>
               </div>
               <div className="button-pane">
               <button className="button-main button-block">Edit Club</button>
               </div>
               </div>
          );
     }
}

export default withRouter(SettingsEditClub);
