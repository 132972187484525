import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router';
import jsCookie from "js-cookie";
import Menu from './menu';
import { userLogOut } from './../actions/userActions';

class App extends Component {

     componentWillMount(){
          if(this.props.user.user_signed_in===false){
               this.props.router.push('/');
          }
     }

     performSearch(searchKeyword){

     }
     logOut(){
          this.props.dispatch(userLogOut());
          this.props.router.push('/')
     }

     render() {
          return (
               <div className="app-container">
                    <div className="top-navigation">
                         {/* <Link to="app"><button className="button-top-home"></button></Link> */}
                         
                         <div className="main-menu">
                              <Link to="app"><button type="button" className="btn menuItem" >All Tracks</button></Link>
                              <Link to="outstanding"><button type="button" className="btn menuItem" >To React</button></Link>
                              <Link to="downloads"><button type="button" className="btn menuItem" >Downloads</button></Link>
                              <Link to="priorities"><button type="button" className="btn menuItem" >Priorities</button></Link>
                              <Link to="settings"><button type="button" className="btn menuItem" >Settings</button></Link>
                              <button type="button" className="btn menuItem" onClick={ () => this.logOut() }>Logout</button>
                         </div>
                    </div>
                    <div className="layout-container">
                         <div>
                              {this.props.children}
                         </div>
                    </div>
                    <Menu ref="sideMenu"></Menu>
               </div>
          );
     }
}

function mapStateToProps(store){
     return {
          user: store.user,
     };
}

export default withRouter(connect(mapStateToProps)(App));
