import React, { Component } from 'react';
import { connect } from 'react-redux';

import LoadingScreen from './loading-screen';
import PriorityItem from './priority-item';

import { priorityFetch } from './../actions/priorityActions'

class Priorities extends Component {

     componentWillMount() {
          this.props.dispatch(priorityFetch());
     }

     renderPriorities () {

          if(this.props.priorities.priorities_available){

               let PriorityItemList = [];
               this.props.priorities.priorities.map((priority, i) => {
                    PriorityItemList.push(<PriorityItem
                                             key={i}
                                             content={priority}
                                             />);
                    return true;
               });

               return (
                    <div className="priority">
                         { PriorityItemList }
                    </div>
               );

          } else {
               return (<LoadingScreen />);
          }
     }

     render () {
          return (<div>{ this.renderPriorities() }</div>);
     }
}

function mapStateToProps(store){
     return {
          priorities: store.priorities,
     };
}

export default connect(mapStateToProps)(Priorities);
