const defaults = {
     clubs_available: false,
     clubs: []
}

const settingsReducer = (state = defaults, action) => {

     switch(action.type){
          case "SETTINGS_GET_CLUBS": {
               state = {...state, clubs_available: false };
               break;
          }
          case "SETTINGS_GET_CLUBS_ERROR": {
               state = {...state, clubs_available: false };
               break;
          }
          case "SETTINGS_GET_CLUBS_DONE": {
               state = {...state,
                    clubs_available: true,
                    clubs: action.payload
                    };
               break;
          }
          default: {
              break;
          }
     }

     return state;
}

export default settingsReducer;
