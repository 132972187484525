import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import LoadingScreen from './loading-screen';
import { userLogin, userLoginQl } from './../actions/userActions';
import jsCookie from "js-cookie";

class Login extends Component {

     constructor(state){
          super(state)
          
          this.state = {
               username: '',
               password: '',
               showQuickLink: this.props.route.quickLinkCode ? true : false,
          }
          this.onKeyUp = this.onKeyUp.bind(this);
     }

     setUsername(event) {
          this.setState({
               username: event.target.value
          });
     }

     setPassword(event) {
          this.setState({
               password: event.target.value
          });
     }

     logInUser(){
         let login_object = {
              username: this.state.username,
              password: this.state.password
         }
         this.props.dispatch(userLogin(login_object));
     }
     onKeyUp(event) {
          if (event.charCode === 13) {
            this.logInUser();
          }
      }


     componentDidMount() {
         
         if(this.props.route.quickLinkCode.length>0){
             let login_object = {
                  quickLinkCode: this.props.route.quickLinkCode
             }
             this.props.dispatch(userLoginQl(login_object));
         }
         

     }

     componentDidUpdate() {
         if(this.props.user.user_signed_in){
              this.props.router.push('app');
         }
     //     if(jsCookie.get("user_id")){
     //           this.props.router.push('app');
     //      }
     }

     render() {
         

          let { user } = this.props;
          let loginErrorMessage = "";
          let loginScreen = "";
          

          if(user.ql_link_fail===true){
              loginErrorMessage = (<div className="login-window-error-message">Your link has expired, please enter your login details below!</div>);
          } else if(user.failed_login){
              loginErrorMessage = (<div className="login-window-error-message">Login Failed - Please try again!</div>);
          }

          if(this.state.showQuickLink&&user.ql_link_fail===false){
              loginScreen = (<div><h2>Quick Link</h2><p>Please wait while we transfer you to the track.</p></div>)
          } else {
              loginScreen = (<div>
                      <h2>Sign-In</h2>
                      <p>Please enter your React Power username and password in the field below.</p>
                      { loginErrorMessage }
                      <input className="form-control" placeholder="Your Username" value={ this.state.username } onChange={ this.setUsername.bind(this) } />
                      <input className="form-control" placeholder="Your Password" onKeyPress={ this.onKeyUp } value={ this.state.password } onChange={ this.setPassword.bind(this) } type="password" />
                      <button className="button-main button-block" onClick={ () => this.logInUser() }>Sign-In</button>
                  </div>
              );
          }

          if(user.started_login){
               return (<LoadingScreen />);
          }


          return (
               
               <div className="login-window">
                    <h1>POWER DJ <strong>REACT</strong></h1>
                    { loginScreen }
               </div>
          );
     }

}

function mapStateToProps(store){
     return {
          user: store.user
     };
}

export default withRouter(connect(mapStateToProps)(Login));
